<template>
  <div id="image-compress">
    <b-form-file
      @change="onChange"
      :placeholder="placeholderDefault"
      accept="image/*"
    ></b-form-file>
    <span
        v-if="showButtonRemove"
        @click="removeElementImage()"
        class="text-body-1 mt-2 mb-2 red--text cursor-pointer">
        <v-icon small color="red">mdi-delete</v-icon> xóa image
    </span>
    <!-- <b-img class="mt-1" rounded :src="image_data"></b-img> -->
  </div>
</template>

<script>
import image_compress from "@/mixins/image_compress.js";
export default {
  name: "ImageCompress",
  mixins: [image_compress],
  props: {
    // Pass the files info when it's done
    done: {
      type: Function,
      default: () => {},
    },
    showButtonRemove: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      image_data: null,
      image_file: null,
      placeholderDefault: 'Chọn tệp tin hoặc cancel để xóa',
      // originalImg: '',
    };
  },

  methods: {
    onChange(e) {
      let file = e.target.files[0];
      if (file === undefined) {
        // this.image_data = null;
        // this.image_file = null;
        this.removeElementImage();
        return;
      }
      this.handleFile(file);
    },
    removeElementImage() {
      let empty = {
        blob: null,
        base64: null,
        file: null,
      };
      this.setDefaultLabelImgCompress();
      this.done(empty);
    },
    setDefaultLabelImgCompress() {
      let ele = document.getElementById("image-compress");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent = this.placeholderDefault;
      }
    },
  },
};
</script>
